#success {
  background-color: #B4C9DA;
  height: 100vh;
  overflow: auto;
  padding: 0 5vw;

  .page-title {
    box-sizing: border-box;
    font-size: 2em;
  }

  .sub-title {
    box-sizing: border-box;
    font-size: 1.2em;
    margin-bottom: 1.2em;
  }

  .desc {
    ul {
      padding: 0;
      list-style: none;

      li {
        p {
          margin: 10px 0;
        }

        ul {
          padding: 0;
          list-style: none;

          li {
            margin: 5px 0;
          }
        }
      }
    }
  }

  .guest-info-text {
    padding: 10px 10px 10px 34px;
    margin-left: -34px;
    margin-top: 15px;
    display: inline-block;
  }

  .required-text {
    color: #db4437;
    font-size: 13px;
  }

  .trial-description {
    line-height: 135%;
    margin-top: 22px;
    font-size: 13px;
  }

  .trial-form-box {
    max-width: 640px;
    margin: 110px auto;
    background-color: #ffffff;
    padding: 34px;
    text-align: left;
  }

  .guest-info-text {
    font-size: 20px;
    background-color: #074983;
    color: #ffffff;
  }

  .label-text {
    display: inline-block;
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 20px;
  }

  .required-label {
    color: #db4437;
  }

  .text-input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e2e2e2;
    height: 30px;
    font-size: 16px;
  }

  .submit-trial {
    min-width: 88px;
    padding: 10px 10px;
    font-size: 16px;
    color: #ffffff;
    background-color: #4285f4;
    font-weight: bold;
  }

  .radio-label {
    display: inline-block;
    margin: 15px 0;
  }
}
